<template>
    <div id="Sign1">
        <div class="hadheight"></div>
        <Header />
        <div class="signbox-sec1 base100">
            <div class="basecont">
                <div class="cont">
                    <div class="sign_nav">
                        <router-link to="/sign/scancode" class="item basefont30">
                            扫码登录
                        </router-link>
                        <router-link to="/sign/account" class="item basefont30">
                            账户登录
                        </router-link>
                    </div>
                    <router-view></router-view>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
    name: "Sign",
    components:{
        Header,
        Footer
    },
    
}
</script>
